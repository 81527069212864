import "./App.css";
import Cookies from "js-cookie";
import { useState } from "react";
import { Buffer } from "buffer";
import Response from "./component/Response";
import properties from "./component/Properties";
import PulseLoader from "react-spinners/PulseLoader";
import ErrorPage from "./component/ErrorPage";
import { env } from "./env";

const App = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  let missing = false;

  document.body.onload = async () => {
    const authRequest = {
      method: properties.GET,
      credentials: "include",
    };

    let authResponse = null;
    try {
      authResponse = await fetch(env.REACT_APP_TOKEN_ENDPOINT, authRequest);
    } catch (err) {}

    const cookie = Cookies.get("qrCodeResponseEncodedData");
    let walletPayResponse = null;
    try {
      walletPayResponse = await JSON.parse(
        Buffer.from(cookie, "base64").toString()
      );
      if (
        !walletPayResponse.qrCode ||
        !walletPayResponse.payEndtime ||
        !walletPayResponse.sourcePayNumber
      ) {
        missing = true;
      }
    } catch (err) {
      setError(true);
      setLoading(false);
    }

    const request = {
      method: properties.GET,
      credentials: "include",
      headers: new Headers({
        "Content-type": "application/x-www-form-urlencoded",
      }),
    };

    if (walletPayResponse !== null) {
      const templateResponse = await fetch(
        env.REACT_APP_WALLET_PAYMENT_TEMPLATE_URL +
          "?encoded-request=" +
          cookie,
        request,
        true
      );

      if (templateResponse.ok && authResponse.ok && !missing) {
        // successfully fetched the token and template
        const templateText = await templateResponse.text();
        if (templateText && templateText.trim().length > 0) {
          // for valid clientAppId
          setResponse(
            <Response
              msg={templateText}
              walletPaymentResponse={walletPayResponse}
              fetchError={false}
              sign={null}
            />
          );
        } else {
          // for invalid clientAppId
          setResponse(
            <Response
              msg={properties.CLIENT_APP_INVALID_PAYMENT_STATUS}
              walletPaymentResponse={walletPayResponse}
              fetchError={true}
              sign={walletPayResponse.signForInvalidClientAppId}
            />
          );
        }
      } else {
        // failure in fetching the token or template or having any required missing fields in walletPayResponse
        setResponse(
          <Response
            msg={properties.INTERNAL_FAIL_PAYMENT_STATUS}
            walletPaymentResponse={walletPayResponse}
            fetchError={true}
            sign={walletPayResponse.signForInternalFail}
          />
        );
      }
      setLoading(false);
    }
  };
  return loading ? (
    <div id="loader">
      <PulseLoader color={"#36D7B7"} loading={loading} size={35} />
    </div>
  ) : !error ? (
    response
  ) : (
    <ErrorPage />
  );
};
export default App;
